export const isEmptyFilter = (heading, value) => {
  if (!value) {
    return true
  }
  switch (heading.type) {
    case 'P':
    case 'R':
    case 'N':
      return Array.isArray(value) ? !value.some((val) => parseFloat(val)) : true
    case 'C':
    case 'L':
      return Array.isArray(value) ? value.length === 0 : true
    case 'B':
      return value[0] === false && value[1] === false
    default:
      return value === getEmptyValue(heading)
  }
}

export const getEmptyValue = (heading) => {
  switch (heading.type) {
    case 'L':
    case 'C':
      return []
    case 'B':
      return [false, false]
    case 'P':
    case 'R':
    case 'N':
      return ['', '']
    case 'T':
    case 'S':
    default:
      return ''
  }
}

export const hasFilter = (filter) => {
  if (!filter || Object.keys(filter).length === 0) {
    return false
  }

  return Object.keys(filter).some((key) => {
    const v = filter[key]
    if (!v) {
      return false
    }
    if (Array.isArray(v)) {
      if (v.length === 0) {
        return false
      }
      return v.some((val) => !!val)
    }
    return true
  })
}
