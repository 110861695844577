<template>
  <div class="fixed inset-0 z-30">
    <div
      class="
        absolute
        inset-0
        bg-gray-500 bg-opacity-20
        backdrop-filter backdrop-blur-sm
      "
      @click="$emit('close')"
    ></div>
    <div
      class="
        absolute
        right-0
        top-0
        bottom-0
        w-80
        bg-gray-100
        shadow
        flex flex-col
      "
    >
      <div
        class="h-12 flex items-center justify-between border-b border-gray-300"
      >
        <div>
          <button
            class="mx-2 cursor-pointer select-none text-xs rounded py-1 px-2"
            @click="clearFilter"
            :class="{
              'bg-red-700 text-red-50': hasFilter,
              'bg-blue-700 text-blue-50': !hasFilter
            }"
          >
            <span class="mr-2" v-if="hasFilter">{{
              $t('actions.clearFilter')
            }}</span>
            <icon face="mdiRefresh" v-if="busy" spin />
            <icon face="mdiHome" v-if="!busy" class="mr-1" />
            <span v-if="!busy">{{ total }}</span>
          </button>
        </div>
        <button
          type="button"
          class="absolute right-0 top-0 w-12 h-12 text-lg"
          @click="$emit('close')"
        >
          &times;
        </button>
      </div>
      <div class="flex-1 overflow-y-auto">
        <div
          v-for="heading in filterableHeadings"
          :key="heading.id"
          class="border-b border-gray-300"
          :class="{
            'bg-white': expanded.includes(heading.id)
          }"
        >
          <button
            class="
              flex
              w-full
              items-center
              justify-between
              px-4
              py-2
              text-left
              hover:text-blue-700
            "
            @click="toggleExpanded(heading)"
          >
            <span class="flex items-cente">
              <span class="text-sm pr-1">{{ heading.name }}</span>
              <icon
                class="text-blue-700"
                face="mdiAlertCircleOutline"
                v-if="!isEmptyFilter(heading)"
              />
            </span>
            <icon
              face="mdiChevronRight"
              class="transform"
              :class="{
                'rotate-90': expanded.includes(heading.id)
              }"
            />
          </button>
          <div
            v-if="expanded.includes(heading.id)"
            class="border-t border-gray-200 p-4"
          >
            <field
              v-if="heading.type === 'S' || heading.type === 'T'"
              type="text"
              :value="getTextValue(heading)"
              @input="(payload) => setTextValue(heading, payload)"
              :placeholder="$t('general.search')"
              compact
              small
            >
            </field>
            <div
              v-else-if="
                heading.type === 'P' ||
                heading.type === 'R' ||
                heading.type === 'N'
              "
              class="flex items-center"
            >
              <field
                type="number"
                compact
                small
                placeholder="min"
                :value="getNumberValue(heading, 0)"
                @input="(payload) => setNumberValue(heading, payload, 0)"
              />
              <span class="mx-px">&divide;</span>
              <field
                type="number"
                compact
                small
                placeholder="max"
                :value="getNumberValue(heading, 1)"
                @input="(payload) => setNumberValue(heading, payload, 1)"
              />
            </div>
            <div v-else-if="heading.type === 'L'">
              <checkbox
                v-for="chk in heading.source.options"
                :key="chk.value"
                :label="heading.ro ? $t(chk.label) : chk.label"
                :value="getListValue(heading, chk)"
                @input="(payload) => setListValue(heading, payload, chk)"
              />
            </div>
            <div v-else-if="heading.type === 'B'">
              <checkbox
                i18n="bool.yes"
                :value="getBoolValue(heading, 0)"
                @input="(payload) => setBoolValue(heading, payload, 0)"
              />
              <checkbox
                i18n="bool.no"
                :value="getBoolValue(heading, 1)"
                @input="(payload) => setBoolValue(heading, payload, 1)"
              />
            </div>
            <div
              v-else-if="
                heading.type === 'C' && heading.source.name !== 'contacts'
              "
            >
              <div class="h-32 overflow-y-auto -mx-4">
                <checkbox
                  v-for="el in getCollectionItems(heading)"
                  :key="el._id"
                  :label="el.name"
                  :value="getCollectionValue(heading, el)"
                  @input="(payload) => setCollectionValue(heading, payload, el)"
                  class="mx-4"
                />
              </div>
            </div>
            <div
              v-else-if="
                heading.type === 'C' && heading.source.name === 'contacts'
              "
            >
              <filter-contacts
                :value="getContactsValue(heading)"
                @input="(payload) => setContactsValue(heading, payload)"
              />
            </div>
            <div v-else>
              <pre>{{ heading }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FilterContacts from './FilterContacts'

import {
  isEmptyFilter,
  getEmptyValue,
  hasFilter
} from '@/services/filterEstates'

export default {
  name: 'FilterEstates',
  components: { 'filter-contacts': FilterContacts },
  props: {
    headings: Array,
    value: Object,
    total: Number,
    busy: Boolean
  },
  data() {
    return {
      expanded: []
    }
  },
  computed: {
    filterableHeadings() {
      const allowed = {
        S: true,
        L: true,
        C: true,
        P: true,
        R: true,
        T: true,
        N: true,
        B: true
      }
      return this.headings.filter((heading) => allowed[heading.type])
    },
    hasFilter() {
      return hasFilter(this.value)
    }
  },
  methods: {
    clearFilter() {
      this.$emit('input', {})
    },
    isEmptyFilter(heading) {
      const v = this.value?.[heading.id]
      return isEmptyFilter(heading, v)
    },
    toggleExpanded(heading) {
      const xpanded = this.expanded.slice(0)
      const idx = xpanded.indexOf(heading.id)
      if (idx >= 0) {
        xpanded.splice(idx, 1)
      } else {
        xpanded.push(heading.id)
      }
      this.expanded = xpanded
    },
    getEmptyValue(heading) {
      return getEmptyValue(heading)
    },
    getTextValue(heading) {
      return this.value?.[heading.id] ?? this.getEmptyValue(heading)
    },
    setTextValue(heading, payload) {
      this.$emit('input', {
        ...this.value,
        [heading.id]: payload
      })
    },
    getNumberValue(heading, idx) {
      return this.value?.[heading.id]?.[idx] || ''
    },
    setNumberValue(heading, payload, idx) {
      const numberValue =
        this.value?.[heading.id] ?? this.getEmptyValue(heading)
      numberValue[idx] = payload
      this.$emit('input', {
        ...this.value,
        [heading.id]: numberValue
      })
    },
    getListValue(heading, item) {
      const v = this.value?.[heading.id] ?? this.getEmptyValue(heading)
      return v.includes(item.value)
    },
    setListValue(heading, payload, item) {
      const v =
        this.value?.[heading.id]?.slice(0) ?? this.getEmptyValue(heading)
      const idx = v.indexOf(item.value)
      if (payload && idx < 0) {
        v.push(item.value)
      } else if (!payload && idx >= 0) {
        v.splice(idx, 1)
      }
      this.$emit('input', {
        ...this.value,
        [heading.id]: v
      })
    },
    getBoolValue(heading, idx) {
      const v = this.value?.[heading.id] ?? this.getEmptyValue(heading)
      return v[idx]
    },
    setBoolValue(heading, payload, idx) {
      const v =
        this.value?.[heading.id]?.slice(0) ?? this.getEmptyValue(heading)
      v[idx] = !!payload
      this.$emit('input', {
        ...this.value,
        [heading.id]: v
      })
    },
    getCollectionItems(heading) {
      const src = heading.source.name
      const items = this.$store.state?.[src]?.items ?? []
      return items.slice(0).sort((a, b) => a.name.localeCompare(b.name))
    },
    getCollectionValue(heading, item) {
      const v = this.value?.[heading.id] ?? this.getEmptyValue(heading)
      return v.includes(item._id)
    },
    setCollectionValue(heading, payload, item) {
      const v =
        this.value?.[heading.id]?.slice(0) ?? this.getEmptyValue(heading)
      const idx = v.indexOf(item._id)
      if (payload && idx < 0) {
        v.push(item._id)
      } else if (!payload && idx >= 0) {
        v.splice(idx, 1)
      }
      this.$emit('input', {
        ...this.value,
        [heading.id]: v
      })
    },
    getContactsValue(heading) {
      const v = this.value?.[heading.id] ?? this.getEmptyValue(heading)
      return v.slice(0)
    },
    setContactsValue(heading, value) {
      this.$emit('input', {
        ...this.value,
        [heading.id]: value
      })
    }
  }
}
</script>
