<template>
  <page>
    <template #navigation>
      <div class="flex -mx-1">
        <action-icon
          class="mx-1"
          variant="primary"
          face="mdiPencilPlus"
          small
          @click="onCreateItem"
        />
        <div class="flex-1 mx-1">
          <field
            compact
            type="search"
            :placeholder="$t('general.search')"
            small
            v-model="search"
          />
        </div>
        <div class="mx-1 relative">
          <action-icon
            variant="primary"
            face="mdiFilterOutline"
            small
            @click="isFilterShown = true"
          />
          <span
            class="flex h-3 w-3 absolute -top-0.5 -right-0.5"
            @click="isFilterShown = true"
            v-if="hasFilter"
          >
            <span
              class="
                animate-ping
                absolute
                inline-flex
                h-full
                w-full
                rounded-full
                bg-red-700
                opacity-75
              "
            ></span>
            <span
              class="relative inline-flex rounded-full h-3 w-3 bg-red-500"
            ></span>
          </span>
        </div>
      </div>
    </template>
    <grid
      name="estates.list"
      :heading="procHeaders"
      :items="filteredItems"
      :search="debouncedSearch"
      @clicked="onViewItem"
      variant="estates"
      :persist="persisting"
    />
    <filter-estates
      :headings="filterHeaders"
      :busy="!!debouncedFilterTk"
      :total="filteredItems.length"
      v-show="isFilterShown"
      @close="isFilterShown = false"
      v-model="filter"
    />
  </page>
</template>

<script>
import { mapState } from 'vuex'
import { getProcHeaders } from '@/services'
import FilterEstates from '@/components/FilterEstates'
import persistConfig from '@/services/grid/persistConfig'
import { isEmptyFilter, hasFilter } from '@/services/filterEstates'

export default {
  components: { 'filter-estates': FilterEstates },
  data() {
    return {
      search: '',
      debouncedSearch: '',
      debouncedSearchTk: null,
      currentItem: null,
      debouncedFilterTk: null,
      filter: {},
      isFilterShown: false
    }
  },
  beforeDestroy() {
    clearTimeout(this.debouncedSearchTk)
    clearTimeout(this.debouncedFilterTk)
  },
  mounted() {
    this.loadConfig()
  },
  watch: {
    search(to) {
      clearTimeout(this.debouncedSearchTk)
      if (!to) {
        this.debouncedSearch = ''
        return
      }
      this.debouncedSearchTk = setTimeout(() => {
        this.debouncedSearch = to
      }, 300)
    },
    filter: {
      deep: true,
      handler() {
        clearTimeout(this.debouncedFilterTk)
        this.debouncedFilterTk = setTimeout(() => {
          this.debouncedFilterTk = null
        }, 500)
      }
    }
  },
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) =>
        state.settings.loading ||
        state.settings.updating ||
        state.headers.loading ||
        state.headers.updating,
      settings: (state) => state.settings,
      headers: (state) => state.headers,
      estates: (state) => state.estates,
      contacts: (state) => state.contacts,
      zones: (state) => state.zones
    }),
    persisting() {
      return { s: this.debouncedSearch, f: { ...this.filter } }
    },
    hasFilter() {
      return hasFilter(this.filter)
    },
    filterHeaders() {
      if (!this.loaded) {
        return []
      }
      const _settings = this.settings.items.find(
        (item) => item.name === 'estates'
      )
      const _headers = this.headers?.items.find(
        (item) => item.name === 'estates'
      )
      return getProcHeaders(_settings.items, _headers?.items)
    },
    procHeaders() {
      const ph = this.filterHeaders.slice(0)
      // add phones
      const contactIndex = ph.findIndex((item) => item.id === 'contactId')
      if (contactIndex >= 0) {
        const phContactPhones = JSON.parse(JSON.stringify(ph[contactIndex]))
        phContactPhones.sid = 'contactId'
        phContactPhones.id = 'contactIdPhones'
        phContactPhones.name = 'Tel'
        phContactPhones.source.field = 'phones'
        const phContactAgency = JSON.parse(JSON.stringify(ph[contactIndex]))
        phContactAgency.sid = 'contactId'
        phContactAgency.id = 'contactIdAgency'
        phContactAgency.name = 'Ag'
        phContactAgency.source.field = 'isAgency'
        ph.splice(contactIndex + 1, 0, phContactPhones, phContactAgency)
      }

      return ph
    },
    gridHeaders() {
      return this.procHeaders.filter((h) => !!h.show)
    },
    mappedFilterHeaders() {
      return this.filterHeaders.reduce(
        (mapped, h) => ({
          ...mapped,
          [h.id]: h
        }),
        {}
      )
    },
    filterableFilter() {
      return Object.keys(this.filter)
        .filter((headingId) => {
          const h = this.mappedFilterHeaders[headingId]
          return h ? !isEmptyFilter(h, this.filter[headingId]) : false
        })
        .map((headingId) => ({
          heading: this.mappedFilterHeaders[headingId],
          value: this.filter[headingId]
        }))
    },
    filterActions() {
      const _number = (value, filterValue) => {
        if (filterValue[0] && parseFloat(value) < parseFloat(filterValue[0])) {
          return false
        }
        if (filterValue[1] && parseFloat(value) > parseFloat(filterValue[1])) {
          return false
        }
        return true
      }
      const _price = (value, filterValue) => {
        if (typeof value === 'object') {
          return _number(value.p, filterValue)
        }
        return _number(value, filterValue)
      }

      const _collection = (value, filterValue) => {
        if (Array.isArray(value)) {
          return filterValue.some((fv) => value.includes(fv))
        }
        return filterValue.includes(value)
      }

      const _bool = (value, filterValue) => {
        if (filterValue[0] === true && !value) {
          return true
        }
        if (filterValue[1] === true && !!value) {
          return true
        }
        return false
      }

      const _text = (value, filterValue) => {
        const ciValue = value.toLowerCase()
        const fiValue = filterValue.trim().toLowerCase()
        return ciValue.indexOf(fiValue) >= 0
      }

      return {
        P: _price,
        R: _price,
        N: _number,
        C: _collection,
        L: _collection,
        B: _bool,
        other: _text
      }
    },
    filterBy() {
      return this.filterableFilter.map((filter) => {
        const filterValue = filter.value
        const filterHeading = filter.heading
        return (estate) => {
          const estateValue = estate[filterHeading.id]
          if (!estateValue) {
            return false
          }
          const func =
            this.filterActions?.[filterHeading.type] ?? this.filterActions.other
          return func(estateValue, filterValue)
        }
      })
    },
    filteredItems() {
      if (!this.estates?.items) {
        return []
      }
      const items = this.estates.items.filter((estate) => estate.status !== 'A')
      if (!this.hasFilter) {
        return items.slice(0)
      }

      if (this.debouncedFilterTk) {
        return []
      }

      return items
        .slice(0)
        .filter((estate) => this.filterBy.every((func) => func(estate)))
    }
  },
  methods: {
    onCreateItem() {
      this.$router.push('/estates/create')
    },
    onViewItem(selected) {
      this.$router.push(`/estates/${selected._id}/view`)
    },
    loadConfig() {
      const config = persistConfig.load('estates.list')
      this.search = config?.s ?? ''
      this.filter = config?.f ?? {}
    }
  }
}
</script>
