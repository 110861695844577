<template>
  <div>
    <div class="max-h-32 overflow-y-auto -mx-4">
      <checkbox
        v-for="el in displayValue"
        :key="el.value"
        :label="el.label"
        :value="true"
        @input="handleRemoveValue(el.value)"
        class="mx-4"
      />
    </div>
    <div class="flex items-center space-x-4 mt-4">
      <button
        class="
          rounded
          tag
          inline-flex
          items-center
          text-sm
          cursor-pointer
          select-none
        "
        @click="pickerShown = true"
      >
        <span class="bg-green-600 text-white"
          ><icon face="mdiFilterOutline"
        /></span>
        <span class="bg-green-600 text-white">{{ $t('general.filter') }}</span>
      </button>
      <button
        v-if="value && value.length > 0"
        class="
          rounded
          tag
          inline-flex
          items-center
          text-sm
          cursor-pointer
          select-none
        "
        @click="clearFilter"
      >
        <span class="bg-red-600 text-white"
          ><icon face="mdiWindowClose"
        /></span>
        <span class="bg-red-600 text-white">{{ $t('actions.eliminate') }}</span>
      </button>
    </div>
    <backdrop v-if="pickerShown" @close="hidePicker">
      <div class="absolute top-3 inset-x-14 flex items-center justify-center">
        <div>
          <field
            compact
            type="text"
            :placeholder="$t('general.search')"
            small
            v-model="search"
          />
        </div>
      </div>
      <div class="absolute top-14 inset-x-2 bottom-2">
        <grid
          class="bg-white"
          :heading="procHeaders"
          :items="filteredItems"
          :maximize="false"
          :value="value"
          @input="handleSelection"
          click-behaviour="model-array"
          variant="contacts"
        />
      </div>
    </backdrop>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getProcHeaders } from '@/services'

export default {
  name: 'FilterContactsModal',
  props: {
    value: { type: Array, default: () => [] }
  },
  data() {
    return {
      search: '',
      pickerShown: false
    }
  },
  computed: {
    ...mapState({
      loaded: (state) => state.loaded,
      isBusy: (state) =>
        state.settings.loading ||
        state.settings.updating ||
        state.headers.loading ||
        state.headers.updating,
      settings: (state) => state.settings,
      headers: (state) => state.headers,
      contacts: (state) => state.contacts
    }),
    procHeaders() {
      if (!this.loaded) {
        return []
      }
      const _settings = this.settings.items.find(
        (item) => item.name === 'contacts'
      )
      const _headers = this.headers?.items.find(
        (item) => item.name === 'contacts'
      )
      return getProcHeaders(_settings.items, _headers?.items)
    },
    gridHeaders() {
      return this.procHeaders.filter((h) => !!h.show)
    },
    editHeaders() {
      return this.procHeaders.filter((h) => h.fill !== false)
    },
    filteredItems() {
      if (!this.contacts?.items) {
        return []
      }
      const _items = this.contacts.items.slice(0)
      const _search = this.search.trim().toLocaleLowerCase()
      if (!_search) {
        return _items
      }
      return _items.filter((item) =>
        item.name?.toLowerCase()?.includes(_search)
      )
    },
    displayValue() {
      return this.value
        .map((id) => {
          const c = this.contacts.items.find((contact) => contact._id === id)
          return c ? { value: c._id, label: c?.name } : null
        })
        .filter((item) => !!item)
    }
  },
  methods: {
    handleSelection(value) {
      this.$emit('input', value)
    },
    handleRemoveValue(value) {
      const v = this.value.slice(0)
      const idx = v.findIndex((val) => val === value)
      if (idx >= 0) {
        v.splice(idx, 1)
      }
      this.$emit('input', v)
    },
    hidePicker() {
      this.pickerShown = false
    },
    clearFilter() {
      this.$emit('input', [])
    }
  }
}
</script>
